.member{
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}

.role-type{
  display: flex;
}

.Addexecutive-container{
   display: flex;
   /* align-items: center; */
   justify-content: space-around;
  }

.form-group{
    margin: 1rem;
}

.more-projects{
    display: flex;
    justify-content: end;
    margin: 1rem;
}