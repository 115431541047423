.LeadDetailHistroy-left{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
}
.history{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(185deg, #ffffff 170%, #b9ffd9 170%);
    border-radius: 0.5rem;
    padding: 0.5rem; 
}