.member{
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}

.role-type{
  display: flex;
  justify-content: space-around;
}

.Addproject-container{
   display: flex;
   align-items: center;
   justify-content: center;
  }

.form-group{
    margin: 1rem;
}

.more-projects{
    display: flex;
    justify-content: end;
    margin: 1rem;
}