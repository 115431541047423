.dashboard-total-num-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem; /* Default gap for small screens */
}

.dashboard-total-num-card-container .dashboard-total-num-card,
.dashboard-executive-card-container .dashboard-executive-card {
  flex: 1 1 calc(100% - 1.5rem); /* Full width on small screens */
  box-sizing: border-box; 
}

/* Medium screens (tablet and up) */
@media (min-width: 768px) {
  .dashboard-total-num-card-container .dashboard-total-num-card,
  .dashboard-executive-card-container .dashboard-executive-card {
    flex: 1 1 calc(50% - 1.5rem); /* 2 cards per row */
  }
}

/* Large screens (desktop and up) */
@media (min-width: 1024px) {
  .dashboard-total-num-card-container .dashboard-total-num-card,
  .dashboard-executive-card-container .dashboard-executive-card {
    flex: 1 1 calc(33.333% - 1.5rem); /* 3 cards per row */
  }
}

/* Extra large screens (large desktop and up) */
@media (min-width: 1200px) {
  .dashboard-total-num-card-container .dashboard-total-num-card,
  .dashboard-executive-card-container .dashboard-executive-card {
    flex: 1 1 calc(25% - 1.5rem); /* 4 cards per row */
  }
}


.dashboard-graph-div {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.dashboard-graph-dropdown {
  display: flex;
  align-items: center; 
  justify-content: flex-end; 
}

.dashboard-source-dropdown {
  display: flex;
  justify-content: flex-end;
  margin: 10px 30px;
}

.dashboard-graph-img {
  width: 100%;
}

.dashboard-executive-container {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}

.dashboard-dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
}

.dashboard-executive-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  
}

.dashboard-title-div {
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-left: 25px;
}

.dashboard-leads-analysis-div p,
.dashboard-executive-analysis-div p {
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-size: 40px;
  margin: 40px 0 20px 0;
  padding-left: 15px;
}

.dashboard-dropdown-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(0, 176, 80);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* Commented out media query */
/* @media only screen and (max-width: 768px) {
  .dashboard-total-num-card-container {
    flex-direction: column;
  }

  .dashboard-executive-card-container {
    justify-content: center; 
  }
} */
