.LeadDetailReq-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.LeadDetailReq-content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LeadDetailReq-left {
  height: 70%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  
}
.LeadDetailReq-header {
  margin-left: 2.5rem;
  padding: 5px;
  display: flex;
  gap: 0.4rem;

  
}
.LeadDetailReq-header-wrapper {
  
  margin-left: 3rem;
}
.LeadDetailReq-container {
  font-family: lato;
  background: linear-gradient(165deg, #00000008 50%, #b9ffd9 170%);
  border-radius: 10px;
}
.LeadDetailReq-right{
display: flex;
flex-direction: column;
text-align: left;
gap: 1rem;
padding: 10px;
}


.popup{
  display: flex;
  justify-content: end;
  padding: 2rem;
}

