/* AddPropertyPage.css */

.add-property-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
}

.add-property-content {
  flex: 1;
}

.image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.thumbnails img {
  width: 70x;
  height: 35px;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s ease;
}

.thumbnails img:hover {
  transform: scale(1.1);
}

.active {
  border: 2px solid #007bff;
}
.developer-dropdown {
  display: flex;
  flex-direction: row;
}

.select {
  width: 23rem;
  height: 2.5rem !important;
  border-radius: 5px;
}
.custom-date-picker {
  /* Apply custom styles here */
  width: 23rem !important;
  height: 40px !important;
}
.form-control {
  width: 23rem;
}
.addproperty-header {
  margin-left: 1rem;
}
label {
  min-width: 35%;
}
.price-range {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.price-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-price,
.price-dropdown {
  width: 150px; 
  min-width: 150px;
}

.errordiv {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.price-tag {
  margin-right: 10px;
}
.radio-buttons {
  display: flex;
  justify-content: space-evenly;
}
.errordiv {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
}
.image-section {
  position: relative;
}

.main-image-container {
  position: relative;
  max-width: 100%;
}

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus-icon {
  font-size: 4rem;
}

.upload-message {
  font-weight: bold;
  margin-top: 0.5rem;
}

.error {
  display: contents;
  color: red;
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other elements */
}

.popup-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in effect */
  width: 90%;
  max-width: 400px;
}

.success-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.success-popup p {
  margin: 0;
  font-size: 18px; 
  font-weight: bold;
}





@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styling */
@media (max-width: 600px) {
  .popup-content {
    padding: 1.5rem;
    width: 80%;
  }
  .success-icon {
    width: 50px;
    height: 50px;
  }
  p {
    font-size: 1rem;
  }
}


