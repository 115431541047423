/* .ViewDeveloper-container{
    display: contents;
    justify-content: center;
} */

.icon{
    padding: 1rem;
}

.ViewDeveloper-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


}