.login-container{
  width: 100%;
  height: auto;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
}

.login-container-content{
  
  /* border: 2px solid rgb(197, 122, 24); */
  display: flex;
}

.login-content{
  /* border: 2px solid rgb(24, 37, 155); */
  width: 100%;
} 

.Logo-img{
  /* border: 2px solid rgb(211, 88, 18); */
  margin-left: 170px;
}

.login-content-right{
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoginSideImg-img{

  width: 100%;
  height: 100vh;
}

.admin-login-content{
  /* border: 2px solid rgb(211, 27, 27); */
  display: flex;
  flex-direction: column;
  align-items: left;
  
}

.admin-login-div{
  margin-bottom: 40px;
}

.admin-login-text{
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  color: rgba(0, 0, 0, 1);

}

.admin-login-msg{
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.login-input-label{
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-size: 18px;
  
}

.login-button-div{
  margin-top: 20px;
}

.login-field{
  margin-top: 10px;
  margin-bottom: 20px;
}


.forgot-password-link{
  text-align: center;
  text-decoration: none;
  color: rgba(25, 105, 180, 1);
  
}

.forgot-password-text{
  font-family: "lato", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.login-container-footer{
  /* border: 2px solid rgb(19, 105, 145); */
  margin-top: 60px;
}

.otp-input{
  height: 60px;
  margin:10px;
  width: 60px;
  border-radius: 10px;
  font-size: 30px;
  text-align: center;
  border: 1px solid rgba(225, 225, 225, 1);
}

.otp-input:focus{
  border: 1px solid rgb(16, 182, 80);
}

.otp-input-container{
   display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid blue; */
  flex-direction: column;
}


.timer-container{
  /* text-align: end; */
  /* border: 2px solid red; */
  margin-left: 30%;
  /* max-width: 60%; */
}